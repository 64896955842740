<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <validation-observer ref="edit-policy-form" v-slot="{ invalid }">
      <b-form @submit.prevent="handleSubmitClick">
        <b-form-group label="Name">
          <template slot="label"> Name <span class="text-danger">*</span> </template>
          <validation-provider #default="{ errors }" name="Name" rules="required|min:4">
            <b-form-input
              id="title"
              v-model="formData.title"
              :state="errors.length > 0 ? false : null"
              name="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Description">
          <validation-provider #default="{ errors }" name="Description">
            <b-form-textarea
              rows="3"
              max-rows="5"
              id="description"
              v-model="formData.description"
              :state="errors.length > 0 ? false : null"
              name="Description"
            >
            </b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Status">
          <validation-provider #default="{ errors }" name="Status" rules="required">
            <b-form-select
              selected
              v-model="formData.status"
              :options="statusOptions"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="d-flex justify-content-center w-100">
          <b-button
            type="submit"
            variant="success"
            class="mt-1"
            :disabled="invalid || showSpinner"
          >
            <span class="align-middle" v-if="showSpinner">
              <b-spinner small class="mr-50" variant="light" />
              <span>Please Wait..</span></span
            >
            <span class="align-middle" v-else>
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span>Update</span></span
            >
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BButton,
  BOverlay,
  BBadge,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import PolicyMixins from "../../../mixins/PolicyMixins";

export default {
  mixins: [ResponseMixins, PolicyMixins],
  props: {
    id: {
      type: String,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BOverlay,
    BBadge,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    BSpinner,
    required,
  },

  data() {
    return {
      showOverlay: false,
      showSpinner: false,
      formData: {
        title: null,
        description: null,
        status: null,
      },

      statusOptions: [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 2 },
      ],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getPolicyDetailsAndSetData(this.id);
    },

    getPolicyDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getPolicy(id)
        .then((res) => {
          console.log("Policy", res);
          this.$set(this.formData, "title", res.data.data.title);
          this.$set(this.formData, "description", res.data.data.description);
          this.$set(this.formData, "status", Number(res.data.data.status));
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSubmitClick() {
      this.updatePolicyAndSetData(this.id, this.formData);
    },

    updatePolicyAndSetData(id, data) {
      this.showSpinner = true;
      this.updatePolicy(id, data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
  },
};
</script>

<style></style>
